window.addEventListener('load', (event) => {
  document.querySelectorAll('.hackonguns-plink').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackGoal('SNCMAMPP', 15000);
      fathom.trackEvent('paylink clicks', { _value: 15000});
    });
  });
  document.querySelectorAll('.hackonguns-more').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('learn more clicks');
    });
  });
  document.querySelectorAll('.hackonguns-subscribe').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('subscribe clicks');
    });
  });
  document.querySelectorAll('.hackonguns-resources').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('resource page clicks');
    });
  });
  document.querySelectorAll('.hackonguns-rsvp').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('rsvp clicks');
    });
  });
  document.querySelectorAll('.rw-partner').forEach(item => {
    item.addEventListener('click', event => {
      fathom.trackEvent('rachelwinfree flyer download');
    });
  });
});
